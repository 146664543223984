import React, { useEffect, useState } from "react";
import BlogCard from "./BlogCard";
import blogData from "../resources/blogContent.json"

const Projects = (props) => {

    const [blogContent, setBlogContent] = useState([]);
    
    useEffect(() => {

        setBlogContent(blogData["blogposts"]);

    });
    
    return (
        <div id="blogPage">
            <p className="mainText">Just a bit about what I've been working on recently ...</p>
            {blogContent.map((article, index) => (
                <BlogCard key={article.blog_id} content={article} tagline={(props.tagline === false) ? false : true}/>
            ))
            }
        </div>
        
    );

}

export default Projects;