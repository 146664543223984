import React from "react";
import instagramLogo from "../resources/icons/instagramlogo.png"
import linkedinLogo from "../resources/icons/linkedinlogo.png"
import youtubeLogo from "../resources/icons/youtubelogo.png"
import githubLogo from "../resources/icons/githublogo.png"

const Footer = () => {

    return (
        <footer id="footer">
            <div id="contactRow">
                <div id="socialDiv">
                    <a href="https://www.instagram.com\sebastian_jaskowski" target="_blank" rel="noreferrer">
                        <img alt="Instagram" className="socialImg" src={instagramLogo} width="40px" height="40px"/>
                    </a>
                    <a href="https://www.linkedin.com/in/sebastian-jaskowski-9121551b7/" target="_blank" rel="noreferrer">
                        <img alt="LinkedIn" className="socialImg" src={linkedinLogo} width="40px" height="40px"/>
                    </a>
                    <a href="https://github.com/Sebiancoder" target="_blank" rel="noreferrer">
                        <img alt="Youtube" className="socialImg" src={githubLogo} width="40px" height="40px"/>
                    </a>
                    <a href="https://www.youtube.com/@sebastianjaskowski" target="_blank" rel="noreferrer">
                        <img alt="Youtube" className="socialImg" src={youtubeLogo} width="40px" height="40px"/>
                    </a>
                </div>
                <div id="emailDiv">
                    <p className="text contactText">Email me at:</p>
                    <a href="mailto:sjaskowski3@gatech.edu" className="text contactText linkText">sjaskowski3@gatech.edu</a>
                </div>
            </div>
            <hr className="pageBreak"/>
            <p className="text">Created By Sebastian Jaskowski</p>
        </footer>
    );

}

export default Footer;