import React from "react";
import ProfilePic from "../resources/profile_pic.jpg"
import Blog from "./Blog"

const Home = () => {

    return (
        <div id="homePage">
            <div id="intro">
                <div id="profile-pic">
                    <img id="ppimg" src={ProfilePic} height={400}></img>
                    <h5 id="hello" align="left">Hello!</h5>
                </div>
                <div id="desc">
                    <p className="fptext">I am a software engineer, ML researcher, and data scientist.</p>
                    <br/>
                    <p className="fptext">Check out my posts to see what I'm working on</p>
                </div>
            </div>
            <div id="fpBlog">
                <Blog tagline={true}/>
            </div>
        </div>
    );

}

export default Home;