import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Components/Home'
import Blog from './Components/Blog';
import Article from './Components/Article';

const Main = () => {
  return (
    <Routes> {/* The Switch decides which component to show based on the current URL.*/}
      <Route exact path='/' element={<Home/>}></Route>
      <Route exact path='/blog' element={<Blog/>}></Route>
      <Route exact path='/article' element={<Article/>}></Route>
    </Routes>
  );
}

export default Main;