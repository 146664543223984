import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router';
import { Link } from "react-router-dom";
import SmallProfilePic from "../resources/small_profile_pic.jpg";
import Markdown from 'react-markdown';
import rehypeRaw from "rehype-raw";

const Article = (props) => {

    const { state } = useLocation();

    const [markdownText, setMarkdownText ] = useState("Article Loading");

    const content = state.bcontent

    const images = require.context('../resources/bimg/', true);
    const markdown = require.context('../resources/blog_markdown/', true)

    useEffect(() => {

        fetch(markdown("./" + content.blog_id + ".md")).then(res => res.text()).then(text => setMarkdownText(text));

    }, [])
    
    return (
        <div id="articlePage">
            <h1 className="articleTitle">{content.title}</h1>
            <div className="dates">
                <p className="articleText post_date">{"Posted " + content.post_date}</p>
                <p className="articleText post_date">  -  </p>
                <p className="articleText post_date">{"Last Edited " + content.edit_date}</p>
            </div>
            <img className='article_headimg' src={images("./" + content.blog_id + "/main_pic.jpg")} />
            <div className="articleContent">
                <Markdown className='articleMarkdown' components={{img:({node,...props})=><img style={{maxWidth:'500px'}}{...props}/>}}>{markdownText}</Markdown>
            </div>
            <div id="authorDiv">
                <img className="smallPP" src={SmallProfilePic}/>
                <div id="authorInfo">
                    <p className="authorText">Written By Sebastian Jaskowski</p>
                    <Link to={"/blog"}>See more articles about tech, AI, and more</Link>
                </div>
            </div>
        </div>
    );

}

export default Article;