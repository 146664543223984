import './App.css';
import Main from "./Main.js"
import NavBar from './Components/Navbar';
import Footer from './Components/Footer';

function App() {
  return (
    <div className="App">
      <div id="vs">
        <NavBar/>
        <Main id="main"/>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
